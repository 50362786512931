import React, { useState } from "react"
import { TeamMemberCardWrapper } from "./TeamMember.styles"
import Image from "gatsby-image"
import ReactCardFlip from "react-card-flip"

const TeamMember = ({ teamMember }) => {
  const [flipped, setFlipped] = useState(false)
  const {
    name,
    position,
    image,
    linkedinLink,
    description: { description },
    previouslyWorkedAs,
  } = teamMember

  const imgSrc = asset => {
    const url = new URL('https:' + asset.file.url);
    url.searchParams.append('w', '231');
    return url.href;
  }

  let returnTeamMember = (
    <>
      <div className="d-block">
        <ReactCardFlip isFlipped={flipped} flipDirection="horizontal" className="position-relative">
          <div className="text-center pt-4 pb-4">
            <img src={imgSrc(image)} />
            <div className="name nunito font-weight-bold">{name}</div>
            <div className="position nunito">{position}</div>
            {!linkedinLink && (<div className="plus-icon" onClick={() => setFlipped(!flipped)}></div>)}
          </div>
          {!linkedinLink && (<div className="p-4 d-flex justify-content-center flex-column h-100 desc poppins">
            <p className="description poppins color-fefefe">{description}</p>
            {previouslyWorkedAs && (
              <>
                <p className="color-fefefe">
                  <strong className="poppins">Previously worked as:</strong>
                </p>
                <ul className="prev">
                  {previouslyWorkedAs.map((job, index) => (
                    <li className="poppins" key={index}>
                      {job}
                    </li>
                  ))}
                </ul>
              </>
            )}
            <div className="minus-icon"></div>
          </div>)}
        </ReactCardFlip>
      </div>
    </>
  );

  if (linkedinLink) {
    returnTeamMember = (
      <a href={linkedinLink} target="_blank">
        <TeamMemberCardWrapper>
          {returnTeamMember}
        </TeamMemberCardWrapper>
      </a>
    );
  } else{
    returnTeamMember = (
    <TeamMemberCardWrapper onClick={() => setFlipped(!flipped)}>
      {returnTeamMember}
    </TeamMemberCardWrapper>
    )
  }

  return (returnTeamMember);
}

export default TeamMember
