import React from "react";
import { useStaticQuery, graphql } from "gatsby"

const GlobeMapSmall = () =>{
    const data = useStaticQuery(graphql`
        {
        allFile(filter: { relativeDirectory: { eq: "our_story" }, name: {eq: "globe-map-small"} }) {
            edges {
            node {
                childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            }
        }
        }
    `)

    return <img src={data.allFile.edges[0].node.childImageSharp.fluid.src} className="w-100 globe-map-img"/>;
};

export default GlobeMapSmall;