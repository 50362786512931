import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Divider from "./Divider"
import { motion, useTransform } from "framer-motion"
import { OurStoryWrapper } from "./OurStory.styles"
import StoryTimeline from "./StoryTimeline"
import StoryTimelineMobile from "./StoryTimelineMobile"

const OurStory = ({ scrollYProgress }) => {

  const base_animation_trigger_extra_large = .54;
  const base_animation_trigger_large = .52;
  const base_animation_trigger_medium = .46;

  const rangeOffsetXl = [.05, .1, .15, .22, .28, .36];
  const dividerHeightXl = [330, 385, 305, 495, 430, 570];

  const rangeOffsetLg = [.05, .1, .15, .22, .28, .35];
  const dividerHeightLg = [355, 475, 355, 575, 530, 650];

  const rangeOffsetMd = [.02, .07, .11, .18, .29, .39];
  const dividerHeightMd = [460, 615, 460, 775, 670, 900];

  return (
    <OurStoryWrapper className="pt-5 pb-5 px-3 poppins d-block">
      <Container>
        <h2 className="h1 mt-4 mb-5 my-lg-5 text-white font-weight-bold text-center">Our Story</h2>
        <div className="d-none d-xl-block">
          <StoryTimeline
            scrollYProgress={scrollYProgress}
            rangeOffset={rangeOffsetXl}
            dividerHeight={dividerHeightXl}
            base_animation_trigger={base_animation_trigger_extra_large}
          />  
        </div>
        <div className="d-none d-xl-none d-lg-block">
          <StoryTimeline
            scrollYProgress={scrollYProgress}
            rangeOffset={rangeOffsetLg}
            dividerHeight={dividerHeightLg}
            base_animation_trigger={base_animation_trigger_large}
          />  
        </div>
        <div className="d-none d-lg-none d-md-block">
          <StoryTimeline
            scrollYProgress={scrollYProgress}
            rangeOffset={rangeOffsetMd}
            dividerHeight={dividerHeightMd}
            base_animation_trigger={base_animation_trigger_medium}
          />  
        </div>
        <div className="d-block d-md-none">
          <StoryTimelineMobile/>  
        </div>
      </Container>
    </OurStoryWrapper>
  )
}

export default OurStory
