import React from "react"
import { Row, Col } from "react-bootstrap"
import Divider from "./Divider"
import { motion, useTransform } from "framer-motion"
import { StoryTimelineWrapper } from "./StoryTimeline.styles"

const StoryTimeline = ({ scrollYProgress, rangeOffset, dividerHeight, base_animation_trigger }) => {

  return (
    <StoryTimelineWrapper>
        <Divider
          height={dividerHeight[0]}
          range={[base_animation_trigger, base_animation_trigger+rangeOffset[0]]}
          scrollYProgress={scrollYProgress}
          style={{zIndex: "5"}}
        />
        <Row>
          <Col xs={6} className="text-right">
            <motion.div
              className=""
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger, base_animation_trigger+rangeOffset[0]], [0, 1]),
                transformOrigin: "right top",
              }}
            >
              <h3 className="h1 mb-4 ambit font-weight-bold color-sky-blue">2015</h3>
              <p className="h3 mb-3 ambit font-weight-bold color-sky-blue">
                APEXX founders had an epiphany
              </p>
              <p className="text-white">
                Our wide experience of the payments industry had revealed that the merchant community was being
                overcharged and under-serviced, encumbered with legacy technology systems, and the market was suffering
                from an innovation deficit. As a result, we decided to build a one stop shop for all merchant payment
                needs with complete provider agnosticism to always deliver the best result for the merchant.
              </p>
            </motion.div>
          </Col>
        </Row>
        <Divider
          height={dividerHeight[1]}
          range={[base_animation_trigger+rangeOffset[0], base_animation_trigger+rangeOffset[1]]}
          scrollYProgress={scrollYProgress}
          style={{zIndex: "4"}}
        />
        <Row className="flex-row-reverse">
          <Col xs={6} className="text-left pl-5">
            <motion.div
              className=""
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+rangeOffset[0], base_animation_trigger+rangeOffset[1]], [0, 1]),
                transformOrigin: "left top",
              }}
            >
              <h3 className="h1 mb-4 ambit font-weight-bold color-sky-blue">2016</h3>
              <p className="h3 mb-3 ambit font-weight-bold color-sky-blue">
                APEXX began an intensive tech build that would lay the foundation for the groundbreaking APEXX Gateway
                product that exists today.
              </p>
              <p className="text-white">
                During this period, we started to pull together the extensive partnership list that now gives us global
                acquiring services with partners all over the globe, as well as over 200 local APM integrations, and
                unrivalled access to additional providers of fraud, DCC, and much more besides.
              </p>
            </motion.div>
          </Col>
        </Row>
        <Divider
          height={dividerHeight[2]}
          range={[base_animation_trigger+rangeOffset[1], base_animation_trigger+rangeOffset[2]]}
          scrollYProgress={scrollYProgress}
          style={{zIndex: "3"}}
        />
        <Row>
          <Col xs={6} className="text-right">
            <motion.div
                className=""
                style={{
                  scale: useTransform(scrollYProgress, [base_animation_trigger+rangeOffset[1], base_animation_trigger+rangeOffset[2]], [0, 1]),
                  transformOrigin: "right top",
                }}
              >
                <h3 className="h1 mb-4 ambit font-weight-bold color-sky-blue">2017</h3>
                <p className="h3 mb-3 ambit font-weight-bold color-sky-blue">
                  APEXX entered the marketplace
                </p>
                <p className="text-white">
                  APEXX entered the marketplace to a fanfare of industry acclaim, scooping several innovation awards across the payments industry as the marketplace recognised the disruptive intent and capabilities of the APEXX platform. Recognition came from the Payments Awards in the form of the ‘Most Disruptive Payment Solution’ Award as well as several other high profile industry accolades. 
                </p>
              </motion.div>
            </Col>
        </Row>
        <Divider
          height={dividerHeight[3]}
          range={[base_animation_trigger+rangeOffset[2], base_animation_trigger+rangeOffset[3]]}
          scrollYProgress={scrollYProgress}
          style={{zIndex: "2"}}
        />
        <Row className="flex-row-reverse">
        
          <Col xs={6} className="text-left pl-5">
            <motion.div
                className=""
                style={{
                  scale: useTransform(scrollYProgress, [base_animation_trigger+rangeOffset[2], base_animation_trigger+rangeOffset[3]], [0, 1]),
                  transformOrigin: "left top",
                }}
              >
            <h3 className="h1 mb-4 ambit font-weight-bold color-sky-blue">2018-2019</h3>
            <p className="h3 mb-3 ambit font-weight-bold color-sky-blue">
              APEXX consolidate its place in the payments market by enacting several high-profile partnerships 
            </p>
            <p className="text-white">
              APEXX consolidate its place in the payments market by enacting several high-profile partnerships and dramatically increasing the number of processing merchants and APEXX staff operating from our home in Shoreditch on London’s tech-roundabout. This growth and vision led to APEXX becoming the first company to ever win successive ‘Most Disruptive Payment Solution’ at the Payments Awards. This period also saw APEXX launch our Surcharge Inspector, which was the first tool of its kind to go to market globally.
            </p>
            </motion.div>
            </Col>
            
        </Row>
        <Divider
          height={dividerHeight[4]}
          range={[base_animation_trigger+rangeOffset[3], base_animation_trigger+rangeOffset[4]]}
          scrollYProgress={scrollYProgress}
          hideBall={true}
          style={{zIndex: "1"}}
        />
        <Row>
          <Col xs={6} className="text-right">
          <motion.div
                className=""
                style={{
                  scale: useTransform(scrollYProgress, [base_animation_trigger+rangeOffset[3], base_animation_trigger+rangeOffset[4]], [0, 1]),
                  transformOrigin: "right top",
                }}
              >
            <h3 className="h1 mb-4 ambit font-weight-bold color-sky-blue">2020-2021</h3>
            <p className="h3 mb-3 ambit font-weight-bold color-sky-blue">
              APEXX rise to the challenges presented by the COVID-19 pandemic in some style.
            </p>
            <p className="text-white">
              The unusual, and difficult, year that has been 2020 has seen APEXX rise to the challenges presented by the COVID-19 pandemic in some style. The year began with the successful completion of our Series A fundraise, which secured $10m in funding to be put towards the development of more ground-breaking products. The APEXX vision for payments orchestration has also been hugely backed by the enterprise merchant community. Far-reaching processing partnerships with Tier 1 merchants such as ASOS and Avon are a tribute to this, as well as strategic partnerships with PayPal and the PayPal Commerce Platform (PPCP) are a testament to this. 
            </p>
            </motion.div>
            </Col>
        </Row>
        <Divider
          height={dividerHeight[5]}
          range={[base_animation_trigger+rangeOffset[4], base_animation_trigger+rangeOffset[5]]}
          scrollYProgress={scrollYProgress}
          style={{zIndex: "20"}}
        />
        <Row className="flex-row-reverse">
          <Col xs={6} className="text-left pl-5">
            <motion.div
              className=""
              style={{
                scale: useTransform(scrollYProgress, [base_animation_trigger+rangeOffset[4], base_animation_trigger+rangeOffset[5]], [0, 1]),
                transformOrigin: "left top",
              }}
            >
            <h3 className="h1 mb-4 ambit font-weight-bold color-sky-blue">2022-2023</h3>
            <p className="h3 mb-3 ambit font-weight-bold color-sky-blue">
              In 2022 APEXX accelerated the impressive growth that has consolidated our Payment Orchestration Platform as one of the global market leaders in the world payments market.
            </p>
            <p className="text-white">
              Having forged a processing relationship with both Ryanair and CarTrawler our processing volume soared to well over £1bn for the first time. We also expanded our people operations to the US with our first office in New York City and opened up APEXX Pune and APEXX Bangalore. 2023 saw us raise $25m at Series B in an extremely difficult investment environment, confirming the market faith in APEXX as a market leader in Payment Orchestration. Finally in the Summer of 2023 we relocated and upsized our London HQ to the heart of the City in Farringdon. The future of APEXX Global and Payment Orchestration is looking bright as we look towards 2024!
            </p>
            </motion.div>
            </Col>
        </Row>
    </StoryTimelineWrapper>
  )
}

export default StoryTimeline
