import styled from "styled-components"

export const TeamMemberCardWrapper = styled.article`
    color: #fefefe;
    background: rgba(234, 234, 234, 0.1);
    border-radius: 20px;
    cursor: pointer;

    transition: scale linear 0.2s;
    &:hover{
      scale: 1.05;
    }

  .react-card-flipper {
    overflow: hidden;
    height: 350px !important;
  }
  /* @media (max-width:992px) {
    .profile-image {
      max-width:150px;
    }
    .name {
      font-size: 1rem;
    }
    .position {
      font-size: 0.8rem;
      font-weight: 100;
    }
    .desc {
      font-size: 0.85rem;
    }
  } */
    .name {
      font-size: 1.1rem;
    }
    .position {
      font-size: 1rem;
      font-weight: 100;
      margin-left: 55px;
      margin-right: 55px;
    }
    .description {
      font-size: 0.8rem;
    }
    strong {
      font-size: 0.8rem;
    }
    .prev {
      li {
        font-size: 0.8rem;
      }
    }
    .gatsby-image-wrapper{
      max-height: 250px;
      max-width: 250px;
    }

    .plus-icon, .minus-icon{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      right: 15px;
      bottom: 15px;
      background-color: rgba(143, 197, 234, 0.3);

      &::before{
        content: '';
        width: 16px;
        height: 4px;
        background-color: #E5F4FC;
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
      }

      &.plus-icon::after{
        content: '';
        width: 4px;
        height: 16px;
        background-color: #E5F4FC;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      
    }
`
