import styled from "styled-components";

export const OneTeamThreeContinentsWrapper = styled.section`

    .intro-copy{
        font-size: 1.25rem;
        line-height: 1.875rem;
    }

    .globe-map-img{
        visibility: hidden;
        opacity: 0.7;
        /* animation: showMap 1s ease-in;
        animation-fill-mode: forwards; */
    }

    .sphere-container{
        position: relative;
        width: 70px;
        height: 70px;

        img{
            width: 100%
        }
        @media (max-width: 1199px) {
            width: 50px;
            height: 50px;
        }
        @media (max-width: 991px) {
            width: 40px;
            height: 40px;
        }
    }

    .location-text{
        position: absolute;
        display: inline-block;
        bottom: 9px;
        font-size: 20px;
        font-weight: 600;
        color: #567280;
        @media (max-width: 1199px) {
            font-size: .875rem;
        }
    }

    .london, .new-york, .india{
        margin: 0;
        position: absolute;
        visibility: hidden;
        /* animation: 1s showSpheres 1s ease-in;
        animation-fill-mode: forwards; */
    }

    .london{
        top: 24%;
        left: 43.5%;
        @media (max-width: 1199px) {
            top: 25%;
            left: 44.5%;
        }
        @media (max-width: 991px) {
            top: 25.5%;
            left: 44.5%;
        }
        @media (max-width: 767px) {
            top: 22%;
            left: 43%;
        }

        .location-text{
            right: 70px;
            bottom: 22px;
            @media (max-width: 1199px) {
                right: 45px;
                bottom: 15px;
            }
            @media (max-width: 991px) {
                right: 40px;
                bottom: 11px;
            }
        }
    }

    .new-york{
        top: 32%;
        left: 24%;

        .location-text{
            right: 68px;
            bottom: 20px;
            @media (max-width: 1199px) {
                right: 45px;
                bottom: 15px;
            }
            @media (max-width: 991px) {
                right: 40px;
                bottom: 10px;
            }
        }
    }
    
    .india{
        top: 44%;
        left: 65%;

        @media (max-width: 767px) {
            top: 40%;
            left: 65%;
        }

        .location-text{
            right: 68px;
            bottom: 22px;
            @media (max-width: 1199px) {
                right: 45px;
                bottom: 15px;
            }
            @media (max-width: 991px) {
                right: 40px;
                bottom: 10px;
            }
        }
    }

    .show-map{
        .london, .new-york, .india{
            animation: 1s showSpheres 1s ease-in;
            animation-fill-mode: forwards;
        }

        .globe-map-img{
            animation: showMap 1s ease-in;
            animation-fill-mode: forwards;
        }
    }


    @keyframes showSpheres {
        0% {
            scale: 0;
            visibility: visible;
        }
        100% {
            scale: 1;
            visibility: visible;
        }
    }

    @keyframes showMap {
        0% {
            opacity: 0;
            visibility: visible;
        }
        100% {
            opacity: 0.7;
            visibility: visible;
        }
    }
    
`


/*animation-name: levitate;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out ease-in-out;
    }


    @keyframes levitate {
        from {
            -webkit-transform: translateY(0px);
        }
        45% {
            -webkit-transform: translateY(20px);
        }
        to {
            -webkit-transform: translateY(0px);
        }
    } */