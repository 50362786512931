import React from "react";
import { useStaticQuery, graphql } from "gatsby"

const LocationSpheres = () =>{

    const data = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "our_story/location_spheres"}}) {
                edges {
                node {
                    name
                    childImageSharp {
                    fluid(maxWidth: 200) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
        }
    `);

    const imageItems = {};

    data.allFile.edges.map(item =>{
    imageItems.[item.node.name] = item;
    });

    return(
        <div>
            <div className="london">
                <div className="sphere-container">
                    <div className="location-text poppins">London</div>
                    <img src={imageItems.london.node.childImageSharp.fluid.src} alt={imageItems.london.node.name} />    
                </div>
            </div>
            <div className="new-york">
                <div className="sphere-container">
                    <div className="location-text poppins">New&nbsp;York</div>
                    <img src={imageItems.newYork.node.childImageSharp.fluid.src} alt={imageItems.newYork.node.name} />    
                </div>
            </div>
            <div className="india">
                <div className="sphere-container">
                    <div className="location-text poppins">India</div>
                    <img src={imageItems.india.node.childImageSharp.fluid.src} alt={imageItems.india.node.name} />    
                </div>
            </div>
        </div>
    )
};

export default LocationSpheres;

/* position: absolute;
right: 65px;
top: 22px;
display: none; */