import React from "react"
import { HeroSectionWrapper } from "./HeroSection.styles"
import { Container } from "react-bootstrap"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { navigateTo } from "../../../utils/hooks"
import { LINKS } from "../../../data/pageLinks.data"
import { Link } from "gatsby"

const HeroSection = () => {

  return (
    <HeroSectionWrapper>
      <Container>
        <h1 style={{visibility: "hidden"}}>About APEXX</h1>
        <div className="text-center px-3 px-lg-0 blockquote-container">
          <blockquote className="blockquote text-bold mb-5 primary-blue-text ambit">
          "APEXX is a truly global <Link to={LINKS.paymentOrchestration} className="font-weight-bold blue-aqua">Payment Orchestration Platform</Link> built for enterprise customers. Through our independence
          and customer - first approach, we simplify a complex payments ecosystem”
          </blockquote>
          <ApButton variant="green" className="pl-4 pr-4 mb-5 find-out-button" role="link" onClick={(e)=> navigateTo(e,"contactUs")}>
            Find Out More
          </ApButton>
        </div>
      </Container>
      <div className="hero-video w-100 p-lg-5">
        <Container className="px-3 px-sm-0">
          <div style={{paddingTop: "56.25%", position: "relative"}}>
              <iframe src="https://player.vimeo.com/video/629980206?h=5847764cc3" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%", borderRadius: "20px"}}></iframe>
          </div>
        </Container>
      </div>
    </HeroSectionWrapper>
  )
}

export default HeroSection
