import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const TeamImage = () => {
    const teamImageData = useStaticQuery(graphql`
  {
    allFile(filter: { relativeDirectory: { eq: "our_story" }, name: {eq: "team-image"} }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
  `)

  return <Img
    fluid={teamImageData.allFile.edges[0] && teamImageData.allFile.edges[0].node.childImageSharp.fluid}
    className="hs-img"
    />;
}

export default TeamImage;