import styled from "styled-components"

export const StyledLineWrapper = styled.div`
  width: 12px;
  margin-left: -6px;
  display: flex;
  position: absolute;
  left: 50%;
  flex-direction: column;
  align-items: center;
  .line {
    width: 2px;
    height: inherit;
    background: #18EDD9;
    transform-origin: 50% 0%;
  }
  .circle {
    background-color: #FFFFFF;
    height: 20px;
    width: 20px;
    left: 50%;
    margin-left: -10px;
    border-radius: 50%;
    position: absolute;
    box-sizing: content-box;
  }
`
