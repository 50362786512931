import React from "react"
import { ExecutiveTeamWrapper } from "./ExecutiveTeam.styles"
import { Container, Row, Col } from "react-bootstrap"
import TeamMember from "../TeamMember/TeamMember"
import { useStaticQuery, graphql } from "gatsby"
import TeamImage from "./TeamImage"

const ExecutiveTeam = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulTeamMember(sort: { order: ASC, fields: orderNumber }) {
        nodes {
          name
          previouslyWorkedAs
          id
          linkedinLink
          description {
            description
          }
          position
          image {
            file {
              url
            }
          }
        }
      }
    }
  `)
  
  const teamMembers = data.allContentfulTeamMember.nodes
  return (
    <ExecutiveTeamWrapper className="pt-5 px-0">
      <Container className="px-lg-0 px-3 mt-xl-5 mt-lg-3">
        <h2 className="h1 font-weight-bold ambit color-sky-blue text-center text-md-left">EXECUTIVE MANAGEMENT TEAM</h2>
        <p className="text-white h4 mb-5 mt-3 font-weight-light ambit text-center text-md-left meet-the-team">Meet the executives guiding us to success at <strong className="font-weight-bold">APEXX Global</strong></p>
        <Row>
          {teamMembers.map((teamMember) => (
            <Col xs={12} sm={8} md={6} lg={4} key={teamMember.id} className="mb-4 mx-auto mx-md-0 team-member-item">
              <TeamMember teamMember={teamMember} />
            </Col>
          ))}
        </Row>
      </Container>
      <div className="hero-image w-100 p-lg-5 pt-5 mb-5 px-lg-0 px-3">
        <Container>
          <TeamImage/>
        </Container>
      </div>
    </ExecutiveTeamWrapper>
  )
}

export default ExecutiveTeam
