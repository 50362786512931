import * as React from "react"
import { motion, useTransform } from "framer-motion"
import { StyledLineWrapper } from "./Divider.styles"

const Divider = ({
  height,
  range,
  scrollYProgress,
  style,
  hideBall = false,
}) => {
  const scale = useTransform(scrollYProgress, range, [0, 1])
  const scaleTop = useTransform(scrollYProgress, range, [0, height - 11])

  const ballOpacity = useTransform(scale, scale =>
    scale === 0 || (scale === 1 && hideBall) ? 0 : 1
  )

  return (
    <StyledLineWrapper style={style}>
      <motion.div
        className="line"
        style={{
          height: `${height}px`,
          scaleY: scale,
          transitionTimingFunction: "ease",
          transitionDuration: ".1s",
        }}
      />

      <motion.div
        className="circle"
        style={{
          top: scaleTop,
          opacity: ballOpacity,
          transitionDuration: ".1s",
          transitionProperty: "opacity",
          transitionTimingFunction: "ease",
        }}
      />
    </StyledLineWrapper>
  )
}

export default Divider
