import React, { useRef } from "react";
import { OneTeamThreeContinentsWrapper } from "./OneTeamThreeContinents.styles"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import LocationSpheres from "./LocationSpheres"
import useIntersection from './useIntersection'
import GlobeMapSmall from "./GlobeMapSmall";

const OneTeamThreeContinents = () =>{
    const data = useStaticQuery(graphql`
        {
        allFile(filter: { relativeDirectory: { eq: "our_story" }, name: {eq: "globe-map"} }) {
            edges {
            node {
                childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
                }
            }
            }
        }
        }
    `)

    const ref = useRef();
    const inViewport = useIntersection(ref, '-50px'); // Trigger as soon as the element becomes visible
    
    return(
        <OneTeamThreeContinentsWrapper className="my-5 px-3">
            <Container>
                <Row className="px-3">
                    <h2 className="h1 ambit font-weight-bold primary-blue-text mb-4">One Team . Three Continents</h2>
                    <p className="intro-copy">Our teams work collaboratively across the business to deliver nothing short of perfection for our merchants.  We’ve got 3 offices around the world, from London,   New York to India. And we’re just getting started!</p>
                </Row>
                <Row className={`mt-5 px-3 position-relative ${inViewport ? "show-map" : ""}`}>
                    <div className="d-none d-md-block">
                        <img ref={ref} src={data.allFile.edges[0].node.childImageSharp.fluid.src} className="w-100 globe-map-img"/>
                        <LocationSpheres/>
                    </div>
                    <div className="d-block d-md-none">
                        <GlobeMapSmall/>
                    </div>
                </Row>
            </Container>
        </OneTeamThreeContinentsWrapper>
    )
};

export default OneTeamThreeContinents;