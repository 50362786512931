import styled from "styled-components"
import { colors } from "../../../utils/styles";

export const ExecutiveTeamWrapper = styled.section`

background: linear-gradient(180deg, #3B5CA7 1.22%, #428AC9 99.11%);

.hero-image {
    background: linear-gradient(#0000 50%, #FFF 50%);
    .hs-img{
        border-radius: 20px;
    }
}

.executive-team-heading h6{
    color: ${colors.secondaryBlue2};
}

.meet-the-team{
    line-height: 2.25rem;
}

@media (max-width: 575px) {
    .row{
        margin: 0;
    }  
}



@media (max-width: 575px) {
    .team-member-item{
        max-width: 350px;
    }  
}

`;
