import styled from "styled-components"

export const HeroSectionWrapper = styled.section`
  background: linear-gradient(180deg, rgba(116, 151, 237, 0.3) 0%, rgba(85, 186, 164, 0.3) 100%);
  .blockquote-container{
    margin-top: 150px;
    @media (max-width: 992px) {
      margin-top: 100px;
    }
  }
  .blockquote {
    @media (min-width: 992px) {
      font-size: 2rem;
    }
    span {
      color: rgba(76, 185, 239, 0.73);
    }
  }
  .hero-video {
    background: linear-gradient(#0000 50%, #3b5Ca7 50%);
  }
  .find-out-button {
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`
